<template>
	<div id="PostedProducts">
		<v-container class="lighten-5">
			<v-container>
				<dashboard-core-app-bar />
			</v-container>
			<v-row style="margin-top: 20px">
				<v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
				<v-col cols="12" md="12">
					<v-card class="pa-3" outlined tile>
						<v-card-title>Mise à jour des status </v-card-title>
						<v-card-text style="margin-top: 20px">
							<v-row>
								<v-col cols="12" md="6" sm="12">
									<v-select
										v-model="stateChange"
										item-value="text"
										item-text="value"
										label="Sélectionner le statut"
										:items="statutUpdate"
										outlined
									></v-select>
								</v-col>
								<v-col cols="12" align="right">
									<v-btn
										color="primary"
										:disabled="disableButtonChangeState"
										@click="redirect()"
										>Valider</v-btn
									></v-col
								>
							</v-row>
						</v-card-text>
					</v-card>
					<v-card class="pa-3" outlined tile>
						<v-card-text>
							<v-col>
								<v-row>
									<v-col cols="12" sm="12" md="3">
										<v-autocomplete
											:items="categories"
											item-text="categorie"
											item-value="categorieId"
											label="Categorie de produits"
											chips
											clearable
											hide-details
											hide-selected
											v-model="categorie"
											@input="onProductcategorieChange"
										></v-autocomplete>
									</v-col>
									<v-col cols="12" sm="12" md="3">
										<v-autocomplete
											:items="productTypes"
											item-text="type"
											item-value="typeExternalId"
											label="Sous Categories de produits"
											chips
											clearable
											hide-details
											:disabled="!this.categorie"
											hide-selected
											v-model="type"
											@input="onProductTypesChange"
										></v-autocomplete>
									</v-col>
									<v-col cols="12" sm="12" md="3">
										<v-autocomplete
											:items="productMarks"
											item-text="Mark"
											item-value="MarkExternalId"
											label="Marques de produits"
											chips
											clearable
											hide-details
											hide-selected
											:disabled="!this.categorie"
											v-model="mark"
											@input="onProductMarksChange"
										></v-autocomplete>
									</v-col>

									<v-col cols="12" sm="12" md="3">
										<v-autocomplete
											:items="productStatus"
											chips
											clearable
											hide-details
											hide-selected
											label="Statut de produit"
											v-model="statut"
											item-value="statut"
											@input="onProductStateChange"
										></v-autocomplete>
									</v-col>
									<v-col cols="12" sm="12" md="3">
										<v-checkbox
											label="Produits ajouté manuellement"
											v-model="productFromUser"
											@change="onProductCheckbox"
										></v-checkbox>
									</v-col>
									<v-col cols="12" sm="12" md="3" style="margin-top: 10px">
										<hotel-date-picker
											style="opacity: 90%; z-index: 2"
											:halfDay="true"
											:displayClearButton="true"
											:i18n="frFR"
											:positionRight="true"
											:minNights="1"
											:format="dateFormat"
											:showYear="true"
											:showCloseButton="false"
											:startDate="new Date(new Date().getFullYear(), 0, 1)"
											:endDate="new Date(Date.now() + 3600 * 1000 * 24)"
											@check-in-changed="checkInChanged($event)"
											@check-out-changed="checkOutChanged($event)"
											@clear-selection="clear($event)"
										/>
									</v-col>
								</v-row>
							</v-col>
							<v-card>
								<v-card-title> Produits </v-card-title>
								<v-data-table
									:footer-props="{
										'items-per-page-options': [5, 10, 15, 30],
									}"
									:headers="headers"
									:items="products"
									:search="search"
									:server-items-length="totalProductsCount"
									:options.sync="options"
									:loading="loading"
									class="elevation-1"
									v-model="selectedState"
									item-key="product_id"
									show-select
								>
									<template v-slot:item.product_id="{ item }">
										<v-btn @click="redirectToEditProduct(item)" text>
											<span class="mr-2">{{ item.product_id }}</span>
										</v-btn>
									</template>
									<template v-slot:item.mark="{ item }">
										{{ item.mark }}
									</template>

									<template v-slot:item.type="{ item }">
										{{ item.type }}
									</template>

									<template v-slot:item.name="{ item }">
										{{ item.name }}
									</template>
									<template v-slot:item.state="{ item }">
										<v-col v-if="item.state === 'POSTE'">
											<v-tooltip left max-width="250">
												<template #activator="{ on }">
													<v-chip
														v-on="on"
														class="ma-2"
														color="blue"
														text-color="white"
													>
														Posté
													</v-chip>
												</template>
												<span
													>Le produit est posté et en attente de validation par
													nos administrateurs.
												</span>
											</v-tooltip>
										</v-col>
										<v-col v-if="item.state === 'INVALIDE'">
											<v-tooltip left max-width="250">
												<template #activator="{ on }">
													<v-chip
														v-on="on"
														class="ma-2"
														color="red"
														text-color="white"
													>
														Invalide
													</v-chip>
												</template>
												<span
													>Le produit a été invalidé par nos administrateur car
													il n'est pas conforme par rapports aux saisies faites.
												</span>
											</v-tooltip>
										</v-col>
										<v-col v-if="item.state === 'PUBLIE'">
											<v-tooltip left max-width="250">
												<template #activator="{ on }">
													<v-chip
														v-on="on"
														class="ma-2"
														color="green"
														text-color="white"
													>
														Publié
													</v-chip>
												</template>
												<span
													>Le produit a été validé et publié par nos
													administrateurs, il est visible publiquement et à tous
													les utilisateurs sur la plateforme Mercarue.
												</span>
											</v-tooltip>
										</v-col>
										<v-col v-if="item.state === 'VALIDE'">
											<v-tooltip left max-width="250">
												<template #activator="{ on }">
													<v-chip
														v-on="on"
														class="ma-2"
														color="amber"
														text-color="white"
													>
														Validé
													</v-chip>
												</template>
												<span
													>Le produit a été validé par nos administrateurs et en
													attente de publication.
												</span>
											</v-tooltip>
										</v-col>
									</template>
									<template v-slot:item.date="{ item }">
										{{ item.date }}
									</template>
								</v-data-table>
							</v-card>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-dialog v-model="dialogConfirmState" persistent max-width="600">
					<v-card>
						<v-card-text>
							Êtes-vous sûr de vouloir enregistrer les changements
							?</v-card-text
						>

						<v-card-text>
							<v-icon size="25px">mdi-information-outline</v-icon>
							Attention : Rappel du diagramme de changement de statut :
						</v-card-text>

						<v-img :src="diagramme" max-width="500px" class="center"> </v-img>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="primary darken-1"
								text
								@click="closeDialogConfirmState()"
							>
								Annuler
							</v-btn>
							<v-btn
								color="primary darken-1"
								text
								@click="updateState()"
								:loading="load"
							>
								Enregistrer
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-row>
			<v-row justify="center">
				<v-dialog v-model="dialogMotif" persistent max-width="600">
					<v-form ref="form" v-model="valid" lazy-validation>
						<v-card class="mx-auto px-6 py-8">
							<v-card-title>Veuillez saisir le motif d'invalidité</v-card-title>
							<v-textarea
								label="Motif d'invalidité"
								v-model="motifInvalidite"
								:rules="rules"
							></v-textarea>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="primary darken-1"
									text
									@click="closeDialogMotif()"
								>
									Annuler
								</v-btn>
								<v-btn
									color="primary darken-1"
									text
									@click="validateChamp()"
									:loading="load"
								>
									Enregistrer
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
				</v-dialog>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import db from "../../../firebaseInit";
	import NodesName from "../../utils/nodesName";
	import Constants from "./../../utils/constants";
	import FirebaseDB from "../../utils/firebaseDB";
	import NodesFieldName from "../../utils/nodesFieldName";
	import moment from "moment";
	import FunctionsUtils from "./../../utils/functions";
	import HotelDatePicker from "vue-hotel-datepicker2";
	import "vue-hotel-datepicker2/dist/vueHotelDatepicker2.css";
	import INVALIDE from "@/assets/iconeStatut/INVALIDE.svg";
	import POSTE from "@/assets/iconeStatut/POSTE.svg";
	import PUBLIÉ from "@/assets/iconeStatut/PUBLIÉ.svg";
	import VALIDE from "@/assets/iconeStatut/VALIDE.svg";
	import firebase from "firebase";
	import Diagramme from "../../../../src/assets/diagramme_statut.jpg";

	export default {
		name: "PostedProducts",
		components: {
			DashboardCoreAppBar: () =>
				import("../../materialDashboard/components/core/AppBar.vue"),
			HotelDatePicker,
		},
		data() {
			return {
				categorie: null,
				categories: [],
				INVALIDE: INVALIDE,
				POSTE: POSTE,
				PUBLIÉ: PUBLIÉ,
				VALIDE: VALIDE,
				frFR: {
					night: "jour",
					nights: "jours",
					"check-in": "",
					"check-out": "Date de Creation",
					"day-names": ["lu", "ma", "me", "je", "ve", "sa", "di"],
					"month-names": [
						"Janvier",
						"Février",
						"Mars",
						"Avril",
						"Mai",
						"Juin",
						"Juillet",
						"Août",
						"Septembre",
						"Octobre",
						"Novembre",
						"Décembre",
					],
					tooltip: {
						minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum",
					},
				},
				fromDate: new Date("2021-01-01"),
				toDate: new Date(Date.now() + 3600 * 1000 * 24),
				items: [
					{
						text: "Dashboard",
						disabled: false,
						href: "/",
					},
				],
				productStatus: [
					Constants.POSTE,
					Constants.VALIDE,
					Constants.INVALIDE,
					Constants.PUBLIE,
				],
				dates: [],
				menu: false,
				modalTo: false,
				modalFrom: false,
				products: [],
				totalProducts: [],
				search: "",
				productMarks: [],
				options: {},
				productTypes: [],
				from: null,
				to: null,
				productTypeForAddProduct: null,
				type: null,
				mark: null,
				state: null,
				statut: null,
				totalProductsCount: 0,
				loading: true,
				lastVisibleProduct: null,
				userId: null,
				dates: new Date().toISOString().substr(0, 10),
				menuCalendar: false,
				className: null,
				productFromUser: false,
				statutUpdate: [
					{ text: Constants.VALIDE, value: Constants.LIBELLE_VALIDER },
					{ text: Constants.POSTE, value: Constants.LIBELLE_POSTER },
					{ text: Constants.INVALIDE, value: Constants.LIBELLE_INVALIDER },
					{ text: Constants.PUBLIE, value: Constants.LIBELLE_PUBLIES },
				],
				stateChange: null,
				dialogConfirmState: false,
				selectedState: [],
				load: false,
				diagramme: Diagramme,
				dialogMotif: false,
				motifInvalidite: "",
				rules: [(v) => !!v || "Champ obligatoire"],
				valid: true,
			};
		},
		async beforeRouteEnter(to, from, next) {
			var mark = to.params.mark
				? to.params.mark
				: localStorage.getItem("markForPostedProduct");
			var type = to.params.type
				? to.params.type
				: localStorage.getItem("typeForPostedProduct");
			var statut = to.params.statut
				? to.params.statut
				: localStorage.getItem("statutForPostedProduct");
			if (mark && type && statut) {
				next((vm) => {
					vm.mark = localStorage.getItem("markForPostedProduct");
					vm.type = localStorage.getItem("typeForPostedProduct");
					vm.statut = localStorage.getItem("statutForPostedProduct");
					vm.loading = false;
				});
			} else {
				next((vm) => (vm.loading = false));
			}
		},
		methods: {
			redirect() {
				if (this.stateChange == Constants.INVALIDE) {
					this.dialogMotif = true;
				} else {
					this.dialogConfirmState = true;
				}
			},
			validateChamp() {
				if (this.$refs.form.validate()) {
					this.dialogConfirmState = true;
				}
			},
			closeDialogMotif() {
				this.dialogMotif = false;
				this.$refs.form.reset();
			},

			onProductcategorieChange: function (event) {
				this.type = null;
				this.mark = null;
				// Récupérer les marques de produit
				db.collection(NodesName.PRODUCT_TO_SHARE_MARK)
					.where(NodesFieldName.CATEGORY, "array-contains", event)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							this.productMarks.push({
								Mark: doc.data().name,
								MarkExternalId: doc.data().externalId,
							});
						});
					});
				// Récupérer les types de produit
				db.collection(NodesName.PRODUCT_TO_SHARE_SUBCATEGORIES)
					.where(NodesFieldName.CATEGORY, "==", event)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							this.productTypes.push({
								type: doc.data().libelle,
								typeExternalId: doc.data().externalId,
							});
						});
					});
			},
			clear() {
				this.fromDate = new Date("2021-01-01");
				this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
				this.totalProducts = [];
				this.products = [];
				this.lastVisibleProduct = null;
			},
			checkInChanged(newDate) {
				this.totalProducts = [];
				this.products = [];
				this.lastVisibleProduct = null;
				this.fromDate = newDate;
				this.loadProducts(this.mark, this.type, this.statut);
			},
			checkOutChanged(newDate) {
				this.totalProducts = [];
				this.products = [];
				this.lastVisibleProduct = null;
				this.toDate = newDate;
				this.loadProducts(this.mark, this.type, this.statut);
			},
			onProductTypesChange: function (event) {
				this.totalProducts = [];
				this.products = [];
				this.lastVisibleProduct = null;
				this.loadProducts(this.mark, event, this.statut);
			},
			onProductMarksChange: function (event) {
				this.totalProducts = [];
				this.products = [];
				this.lastVisibleProduct = null;
				this.loadProducts(event, this.type, this.statut);
			},
			onProductStateChange: function (event) {
				this.totalProducts = [];
				this.products = [];
				this.lastVisibleProduct = null;
				this.loadProducts(this.mark, this.type, event);
			},
			onProductCheckbox: function (event) {
				this.totalProducts = [];
				this.products = [];
				this.lastVisibleProduct = null;
				this.loadProducts(this.mark, this.type, this.statut, event);
			},
			redirectToEditProduct(item) {
				this.$router.push({
					name: "ProductDetail",
					params: {
						product_id: item.product_id,
						mark: item.mark,
						type: item.type,
						userId: item.userId,
						statut: item.statut,
					},
				});
			},
			async loadProducts(markSelected, typeSelected, stautSelected) {
				this.loading = true;
				localStorage.setItem("markForPostedProduct", markSelected);
				localStorage.setItem("typeForPostedProduct", typeSelected);
				localStorage.setItem("statutForPostedProduct", stautSelected);
				this.mark = localStorage.getItem("markForPostedProduct");
				this.type = localStorage.getItem("typeForPostedProduct");
				this.statut = localStorage.getItem("statutForPostedProduct");
				if (this.mark || this.type || this.statut) {
					if (!this.fromDate && !this.toDate) {
						this.fromDate = new Date("2021-01-01");
						this.toDate = new Date(Date.now() + 3600 * 1000 * 24);
					}
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					var query = db
						.collection(NodesName.PRODUCT)
						.where(NodesFieldName.CREATION_DATE, ">=", this.fromDate)
						.where(NodesFieldName.CREATION_DATE, "<=", this.toDate);
					if (this.productFromUser == true) {
						query = query.where(
							NodesFieldName.CLASS_NAME,
							"==",
							this.className
						);
					}
					if (
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
						FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
						FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
					) {
						query = query.where(NodesFieldName.MARK, "==", this.mark);
					} else if (
						FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
						FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
					) {
						query = query.where(NodesFieldName.SUBCATEGORY, "==", this.type);
					} else if (
						FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
						FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
					) {
						query = query.where(NodesFieldName.STATE, "==", this.statut);
					} else if (
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
						FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
					) {
						query = query
							.where(NodesFieldName.MARK, "==", this.mark)
							.where(NodesFieldName.SUBCATEGORY, "==", this.type);
					} else if (
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
						FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
					) {
						query = query
							.where(NodesFieldName.MARK, "==", this.mark)
							.where(NodesFieldName.STATE, "==", this.statut);
					} else if (
						FunctionsUtils.isNullOrEmptyOrSpaces(this.mark) &&
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.type) &&
						!FunctionsUtils.isNullOrEmptyOrSpaces(this.statut)
					) {
						query = query
							.where(NodesFieldName.SUBCATEGORY, "==", this.type)
							.where(NodesFieldName.STATE, "==", this.statut);
					} else {
						query = query
							.where(NodesFieldName.MARK, "==", this.mark)
							.where(NodesFieldName.SUBCATEGORY, "==", this.type)
							.where(NodesFieldName.STATE, "==", this.statut);
					}

					var generalStats = await FirebaseDB.getGeneralStats();

					generalStats.forEach((stat) => {
						this.totalProductsCount = stat.data().number_total_products;
					});

					if (this.totalProducts.length < this.totalProductsCount) {
						var querySnapshot = await FirebaseDB.getDocumentsWithPagination(
							query,
							this.lastVisibleProduct,
							itemsPerPage,
							this.totalProductsCount
						);
						let i = 0;
						querySnapshot.forEach((product) => {
							if (Object.entries(product.data()).length !== 0) {
								const found = this.totalProducts.some(
									(el) => el.product_id === product.id
								);

								if (!found) {
									this.totalProducts.push({
										mark: product.data().mark,
										type: product.data().subCategory,
										product_id: product.data().uniqueId,
										name: product.data().name,
										state: product.data().state,
										motifInvalidite: product.data().motifInvalidite,

										userId: product.data().createdBy,
										date: moment(
											new Date(product.data().creationDate.seconds * 1000)
										).format("L HH:mm:ss"),
									});

									i++;
									if (querySnapshot.size == i) {
										this.lastVisibleProduct = product;
									}
								}
							}
						});
					}

					this.products = FunctionsUtils.customSortDataTable(
						this.totalProducts,
						sortBy,
						sortDesc
					);
					this.products = FunctionsUtils.getItemsPerPageForDataTable(
						this.totalProducts,
						itemsPerPage,
						page,
						this.totalProductsCount
					);
				}
				this.loading = false;
			},
			async getClassname() {
				await db
					.collection(NodesName.PRODUCT_CLASS_NAME)
					.where(
						NodesFieldName.CLASS_NAME,
						"==",
						"com.mercarue.model.product.common.Product"
					)
					.get()
					.then((querySnapshot) => {
						querySnapshot.forEach((doc) => {
							this.className = doc.data().className;
						});
					});
			},
			// Permet la mise à jour statut d'un produit
			updateState() {
				this.load = true;

				for (var i = 0; i < this.selectedState.length; i++) {
					if (
						this.stateChange == "PUBLIE" ||
						this.stateChange == "POSTE" ||
						this.stateChange == "VALIDE"
					) {
						db.collection(NodesName.PRODUCT)
							.doc(this.selectedState[i].product_id)
							.update({
								admin_history_productState:
									firebase.firestore.FieldValue.arrayUnion({
										updatedBy: firebase.auth().currentUser.uid,
										updatedDate: new Date(),
										stateFrom: this.selectedState[i].state,
										stateTo: this.stateChange,
										motifInvalidite: null,
									}),
								state: this.stateChange,
								motifInvalidite: null,
								updatedBy: firebase.auth().currentUser.uid,
								updatedDate: new Date(),
							})
							.then(() => {
								this.dialogConfirmState = false;
								this.dialogMotif = false;
								this.load = false;
								this.stateChange = null;
							});
					} else if (this.stateChange == "INVALIDE") {
						db.collection(NodesName.PRODUCT)
							.doc(this.selectedState[i].product_id)
							.update({
								admin_history_productState:
									firebase.firestore.FieldValue.arrayUnion({
										updatedBy: firebase.auth().currentUser.uid,
										updatedDate: new Date(),
										stateFrom: this.selectedState[i].state,
										stateTo: this.stateChange,
										motifInvalidite: this.motifInvalidite,
									}),
								state: this.stateChange,
								motifInvalidite: this.motifInvalidite,
								updatedBy: firebase.auth().currentUser.uid,
								updatedDate: new Date(),
							})
							.then(() => {
								this.dialogConfirmState = false;
								this.dialogMotif = false;
								this.load = false;
								this.stateChange = null;
							});
					}
					// permet d'afficher le nouveau statut du produit directement apres enregistrement des modifications
					this.selectedState[i].state = this.stateChange;
				}
			},
			closeDialogConfirmState() {
				this.dialogConfirmState = false;
				this.dialogMotif = false;
				this.selectedState = [];
				this.stateChange = null;
			},
		},
		created() {
			// Récupérer les categories de produit
			db.collection(NodesName.PRODUCT_TO_SHARE_CATEGORIES)
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						this.categories.push({
							categorie: doc.data().name,
							categorieId: doc.data().uniqueId,
						});
					});
				});
			this.getClassname();
		},
		computed: {
			dateFormat() {
				return "MM/DD/YYYY";
			},
			lastDateAvailable() {
				return this.addYears(new Date(), 2);
			},
			headers() {
				return [
					{ text: "ID", value: "product_id", align: "center" },
					{ text: "Mark", value: "mark", align: "center" },
					{ text: "Sous Categorie", value: "type", align: "center" },
					{ text: "Name", value: "name", align: "center" },
					{ text: "Status", value: "state", align: "center" },
					{
						text: "Motif invalidité ",
						value: "motifInvalidite",
						align: "center",
					},
					{ text: "Date de Creation", value: "date", align: "center" },
				];
			},
			disableButtonChangeState() {
				if (this.selectedState.length > 0 && this.stateChange) {
					return false;
				} else {
					return true;
				}
			},
		},
		watch: {
			options: {
				handler() {
					this.loadProducts(this.mark, this.type, this.statut);
				},
				deep: true,
			},
		},
	};
</script>
<style scoped>
	.center {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
</style>
